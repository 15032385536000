.start_page_img.start_page_img_1 {
    margin-left: 6rem;
}

.start_page_img.start_page_img_2 {
    margin-left: 6rem;
    margin-right: 8rem;
}

@media screen and (max-width: 600px) {
    .start_page_img {
        width: 90%;
        height: auto;
        margin: auto !important;
    }

    .start_page_topic_container {
        margin-left: 0 !important;
        width: 100%;
    }
}

.LandingPageScrollTarget {
    padding-top: 110px;
}
