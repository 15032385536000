body {
  --color-background: #f5f5f9;
  --color-scrollbar: rgb(224, 224, 224);
  --color-scrollbar-hover: #bbb;
  font-weight: 500;
}

[data-theme='dark'] {
  body {
    --color-background: #383838;
    --color-scrollbar: rgb(93, 93, 93);
    --color-scrollbar-hover: #060606;
  }
}

.FingoShapeRadius {
  border-radius: 12px !important;
}

.FingoBorders {
  border: 2px solid #e8e8e8;
}

.FingoPrimaryBorders {
  border: 2px solid #57cc00;
}

[data-theme='dark'] .FingoBorders {
  border: 2px solid #616161;
}

@media (min-width: 576px) {
  .FingoShapeRadius {
    border-radius: 16px !important;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background: var(--color-background);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: var(--color-scrollbar);
  border-radius: 10px;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background: var(--color-background);
  border-radius: 10px;
}

div:where(.swal2-container) {
  z-index: 1063 !important;
}

div:where(.swal2-container) h2:where(.swal2-title) {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #444 !important;
}

div:where(.swal2-container) .swal2-html-container {
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  margin-top: 0.3rem !important;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  background: #fff !important;
  border: none !important;
  border-radius: 16px !important;
  box-sizing: border-box !important;
  color: #545454 !important;
  font-family: inherit !important;
  font-size: 0.9rem !important;
  grid-template-columns: minmax(0, 100%) !important;
  max-width: 95% !important;
  padding: 0 0 1.5em !important;
  position: relative !important;
  width: 25em !important;
}

[data-theme='dark'] div:where(.swal2-container) div:where(.swal2-popup) {
  background-color: #3a3a3a !important;
  color: #fff !important;
}

[data-theme='dark'] div:where(.swal2-container) h2:where(.swal2-title) {
  color: #fbfbfb !important;
}

div:where(.swal2-container) button:where(.swal2-styled) {
  border-radius: 7px !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  margin-top: 4px !important;
  /* padding: 5px 10px !important; */
  min-width: 100px !important;
  transition: all 0.2s ease 0s !important;
}

div:where(.swal2-container) div:where(.swal2-actions) {
  margin: 0.65rem auto 0 !important;
}

div:where(.swal2-container) button:where(.swal2-styled):hover {
  transform: translateY(2px) !important;
}

div:where(.swal2-container).swal2-backdrop-show,
div:where(.swal2-container).swal2-noanimation {
  background: rgb(0 0 0 / 60%) !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: rgb(40, 167, 69) !important;
  border-color: rgb(40, 167, 69) !important;
  box-shadow: rgb(26, 89, 40) 0px 7px !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  background-color: rgb(221 51 51) !important;
  border-color: rgb(221 51 51) !important;
  box-shadow: rgb(154 25 25) 0px 7px !important;
}

.card {
  border: 2px solid rgb(109 109 109 / 10%) !important;
}

.FingoHomeMainContent {
  position: relative;
  padding-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .FingoHomeMainContent {
    padding-bottom: 0;
  }
}

/* icon button */
.FingoIconButton {
  border: none;
  outline: none;
  border-radius: 32px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
}

.progress {
  border-radius: 3rem !important;
}

.alert {
  padding: 0.4rem 1rem !important;
  font-weight: 500 !important;
}

.form-control-lg {
  font-size: 1.05rem !important;
}

/* popover */
.PopoverContentContainer {
  background-color: #fff;
}

[data-theme='dark'] .PopoverContentContainer {
  background-color: #131313;
}

#react-tiny-popover-container {
  z-index: 1060;
}

.FingoAlert {
  color: #fbfbfb;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 14px 12px;
  font-weight: 500;
}

.FingoSnackBar svg {
  width: 24px;
  height: auto;
  margin-right: 0.8rem;
}

.FingoSnackBar.FingoSnackBarSuccess {
  background: #03a856;
}

.FingoSnackBar.FingoSnackBarWarning {
  background: #ffab18;
}

.FingoSnackBar.FingoSnackBarError {
  background: #f84848;
}

[data-theme='dark'] .modal-backdrop.show {
  opacity: 0.7;
}

.InputReferralCodeWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.InputReferralCode {
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 0.9rem;
  width: 100%;
  height: 44px;
  border: 2px solid #e8e8e8;
  border-radius: 12px;
  padding: 0 92px 0 12px;
  background-color: #ffffff;
}

[data-theme='dark'] .InputReferralCode {
  border: 2px solid #101010;
  background-color: #1e1e1e;
  color: #fff;
}

.InputReferralCode:focus {
  border: 2px solid #007bff;
}

.InputReferralCopyBtn {
  border: none;
  outline: none;
  background-color: #edf7ff;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 80px;
  font-weight: 700;
  height: 36px;
  border-radius: 10px;
  font-size: 0.85rem;
}

[data-theme='dark'] .InputReferralCopyBtn {
  background-color: #2d2d2d;
  color: #fff;
}

.InputReferralCopyBtn.active,
.InputReferralCopyBtn:hover {
  background-color: #007bff;
  color: #fbfbfb;
}

.InputReferralCodeInfo {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  border: 2px solid #dce8ff;
  background-color: #eaf2ff;
  border-radius: 6px;
  padding: 6px 8px;
  color: #006ad4;
}

[data-theme='dark'] .InputReferralCodeInfo {
  border: 2px solid #41454d;
  background-color: #212936;
  color: #d0e8ff;
}

.InputReferralCodeInfo svg {
  width: 28px;
  height: auto;
  margin-top: 2px;
  margin-right: 0.4rem;
}

.InputReferralCodeInfo p {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.3;
}

.SocialShareContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.SocialShareItem {
  width: 20%;
  text-align: center;
  padding: 0.5rem;
}

.SocialShareItem button svg {
  width: 36px;
  height: auto;
}

@keyframes fingoBounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7px);
  }
}

.FingoTooltip {
  background-color: #333;
  color: #fff;
  font-size: 0.9rem;
  border-radius: 0.4rem;
  padding: 0.3rem 0.6rem;
}

[data-theme='dark'] .FingoTooltip {
  background-color: #fff;
  color: #000;
}

.modal-notification-recipients .modal-dialog {
  max-width: 500px;
}

.FingoBackgroundColor {
  background-color: #fdfdfd;
}

[data-theme='dark'] .FingoBackgroundColor {
  background-color: #262626;
}

.invalid-feedback {
  font-size: 13px;
  font-weight: bold;
}
