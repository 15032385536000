.FingoModal.ModalVerifyAction {
    z-index: 1062 !important;
}

.FingoModal.ModalVerifyAction:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0 0 0 / 25%);
    z-index: -1;
}

@media (min-width: 576px) {
    .ModalVerifyAction .modal-dialog {
        max-width: 390px;
    }
}

.VerifyActionContainer {
    background-color: #ffffff;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.VerifyActionForm {
    padding: 0.5rem 1.2rem;
}

@media (min-width: 576px) {
    .VerifyActionForm {
        padding: 1.2rem 2rem;
    }
}

@media (min-width: 576px) {
    .ModalVerifyAction .ListReward {
    }
}

.ListRewardHeader h2 {
    font-weight: 700;
    font-size: 21px;
}

.ListRewardHeader p {
    font-size: 0.9rem;
}

[data-theme='dark'] .ModalVerifyAction .VerifyActionContainer {
    background-color: #383838;
}

.ModalVerifyLoadingWrapper {
    background-color: rgba(0, 0, 0, 0.1);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}

[data-theme='dark'] .ModalVerifyAction .VerifyActionContainer {
    .ModalVerifyLoadingWrapper {
        background-color: rgba(255, 255, 255, 0.2);
    }
}

.VerifyActionForm .btn {
    border-radius: 2rem;
}

.VerifyActionForm input {
}

.VerifyActionHeader {
    padding: 0.5rem 1rem;
    background-color: #ffc4c4;
    display: flex;
    justify-content: center;
    color: #b51919;
    align-items: center;
}

.VerifyActionHeader svg {
    width: 26px;
    height: auto;
    margin-right: 0.6rem;
}

.VerifyActionHeader h2 {
    font-size: 22px;
    font-weight: 700;
}

@media (min-width: 576px) {
    .VerifyActionHeader {
        padding: 1rem 1.4rem;
    }
}
