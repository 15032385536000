.FingoButton {
    font-weight: 700;
    border-radius: 7px;
    letter-spacing: 0.65px;
}

.FingoButton.success {
    background-color: rgb(40, 167, 69);
    border-color: rgb(40, 167, 69);
    box-shadow: rgb(26, 89, 40) 0px 7px;
    transition: all 0.2s ease 0s;
}
.FingoButton.HoverEffect.success:active,
.FingoButton.HoverEffect.success:hover {
    transform: translateY(5px);
    background-color: #28a745 !important;
}
.FingoButton.HoverEffect.success:active:focus {
    outline: none;
    border: none;
    box-shadow: rgb(26, 89, 40) 0px 7px !important;
    transform: translateY(5px);
    background-color: #28a745 !important;
}

/* white */
.FingoButton.white {
    background-color: rgb(255, 255, 255);
    border-color: rgb(242, 242, 242);
    box-shadow: rgb(214, 214, 214) 0px 7px;
    transition: all 0.2s ease 0s;
    color: #333;
}
.FingoButton.HoverEffect.white:active,
.FingoButton.HoverEffect.white:hover {
    transform: translateY(5px);
    background-color: #ffffff !important;
}
.FingoButton.HoverEffect.white:active:focus {
    outline: none;
    border: none;
    box-shadow: rgb(224, 224, 224) 0px 7px !important;
    transform: translateY(5px);
    background-color: #ffffff !important;
}

/* color primary */
.FingoButton.primary {
    background-color: #007bff;
    border-color: #007bff;
    box-shadow: rgb(26 84 146) 0px 7px;
    transition: all 0.2s ease 0s;
}
.FingoButton.HoverEffect.primary:active,
.FingoButton.HoverEffect.primary:hover {
    transform: translateY(5px);
    background-color: #007bff !important;
}
.FingoButton.HoverEffect.primary:active:focus {
    outline: none;
    border: none;
    box-shadow: rgb(26 84 146) 0px 7px !important;
    transform: translateY(5px);
    background-color: #007bff !important;
}

/* color danger */
.FingoButton.danger {
    background-color: #f44336;
    border-color: #f44336;
    box-shadow: rgb(139 0 26) 0px 7px;
    transition: all 0.2s ease 0s;
}
.FingoButton.HoverEffect.danger:active,
.FingoButton.HoverEffect.danger:hover {
    transform: translateY(5px);
    background-color: #f44336 !important;
}
.FingoButton.HoverEffect.danger:active:focus {
    outline: none;
    border: none;
    box-shadow: rgb(139 0 26) 0px 7px !important;
    transform: translateY(5px);
    background-color: #f44336 !important;
}

/* variant link */
.FingoButton.btn-link {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none !important;
    transition: all 0.2s ease 0s;
}
.FingoButton.HoverEffect.btn-link:active,
.FingoButton.HoverEffect.btn-link:hover {
    transform: translateY(5px);
    background-color: transparent !important;
}
.FingoButton.HoverEffect.btn-link:active:focus {
    outline: none;
    border: none;
    box-shadow: transparent 0px 7px !important;
    transform: translateY(5px);
    background-color: transparent !important;
}
