.RewardDetail .FingoWaves {
    color: #00b65f;
}

.RewardDetail .Celebrate {
    position: absolute;
    bottom: 0;
    left: 220px;
    width: 100%;
    height: 1px;
}

.RewardDetail .FingoWaves > div {
    background-color: #00b65f;
    height: 180px;
}

.RewardDetail .FingoWaves > svg {
    margin-top: -3px;
}

@media (min-width: 576px) {
    .ModalRewardDetail .modal-dialog {
        max-width: 500px;
    }
    .RewardDetail .FingoWaves > div {
        height: 220px;
    }
}

.RewardDetail {
    background-color: #ffffff;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.RewardDetail .HeaderTitle {
    color: #fff;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 28px;
}

@media (min-width: 576px) {
    .RewardDetail {
    }
}

.RewardDetail h2 {
    font-weight: 700;
    font-size: 22px;
}

@media (min-width: 576px) {
    .ModalRewardDetail .ListReward {
    }
}

[data-theme='dark'] .ModalRewardDetail .RewardDetail {
    background-color: #383838;
}

.RewardDetailContent {
    margin-top: -180px;
    display: flex;
    align-self: center;
    width: 272px;
    flex-direction: column;
    padding-bottom: 1rem;
}

@media (min-width: 576px) {
    .RewardDetailContent {
        width: 400px;
        margin-top: -260px;
    }
}

.RewardDetailContent h6 {
    color: #333;
    font-weight: bold;
    margin-bottom: 1rem;
}

[data-theme='dark'] .RewardDetailContent h6 {
    color: #d6d6d6;
}

.RewardDetailContent p {
    font-size: 0.9rem;
    font-weight: 500;
}

.RewardDetailImg {
    overflow: hidden;
    margin-bottom: 1.2rem;
    border: 2px solid rgb(109 109 109 / 10%);
    background-color: #fff;
}

.RewardDetail img {
    width: 100%;
    height: auto;
}

.RedeemSuccess {
    text-align: center;
}

.RedeemSuccess h6 {
    font-size: 20px;
    font-weight: 700;
}

.RedeemCode {
    font-weight: bold;
    background: #e9f0fa;
    border-radius: 0.4rem;
    height: 38px;
    border: 2px dashed #1379ff;
    cursor: copy;
    position: relative;
    text-align: left;
    display: flex;
    align-items: center;
}

.RedeemCode svg {
    position: absolute;
    top: 8px;
    right: 7px;
    display: flex;
    align-items: center;
    justify-self: center;
    height: 17px;
    width: auto;
}

.RedeemCode code {
    color: #1379ff;
    font-size: 18px;
    margin-left: 58px;
}

.RedeemCodeLabel {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #1379ff;
    margin-bottom: 0;
    color: #fff;
    height: 34px;
    border-radius: 5px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.RedeemSuccess h6 {
    font-size: 18px;
    font-weight: 500;
}

[data-theme='dark'] .RedeemCode {
    background: #2b2616;
}

[data-theme='dark'] .RedeemCode code {
    color: #fff;
}

.ProgressBarTextContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.RewardDetailRedeemText {
    font-size: 14px !important;
    font-weight: 700 !important;
    text-align: center;
}

.RewardDetailLogo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* border: 2px solid rgb(109 109 109 / 10%); */
}

.RewardDetailLogo p {
    font-weight: 700;
}

.RewardDetailLogo img {
    margin-right: 0.4rem;
    width: 32px;
    height: auto;
}

.RewardDetailCurrency {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d2f8e6;
    color: #00753c;
    border-radius: 1.4rem;
    padding: 0.4rem 0.8rem;
}

[data-theme='dark'] .RewardDetailCurrency {
    background-color: #275342;
    color: #d3ece0;
}

.RewardDetailCurrency p {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0;
}

.RedeemPin {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: monospace !important;
    font-size: 18px;
    font-weight: bold;
    border: 2px solid #1379ff;
    background-color: hsl(215.29deg 62.96% 94.71%);
    border-radius: 30px;
    padding: 0 0px 0 20px;
}

[data-theme='dark'] .RedeemPin {
    background-color: #000;
    color: #fff;
}

.RedeemPin input {
    width: 140px;
    border: none;
    outline: none;
    background-color: transparent !important;
    min-width: 20px;
    text-align: center;
    font-size: 20px;
    font-family: monospace !important;
    font-weight: 500 !important;
}

[data-theme='dark'] .RedeemPin input {
    color: #fff !important;
}

.RedeemPin button {
    height: 36px;
    width: 36px;
    border-radius: 36px;
    border: none;
    outline: none;
    margin-left: 0.5rem;
}

.RedeemPin button svg {
    width: 20px;
    height: auto;
}

.GemsNotEnough {
    background-color: #ffedc4;
    color: #3d370c;
    border-radius: 5px;
    padding: 10px 12px;
    display: flex;
    align-items: center;
}

[data-theme='dark'] .GemsNotEnough {
    background-color: #4e4d4a;
    color: #fff;
}

.GemsNotEnough svg {
    width: 26px;
    height: auto;
    margin-right: 10px;
}

.GemsNotEnough p {
    font-size: 15px;
    font-weight: 600;
}
