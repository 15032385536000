.FingoCardCompleteTopic {
    padding: 0.75rem;
    background-color: #f1fcf1;
    border: 2px solid rgb(109 109 109 / 10%);
}

[data-theme='dark'] .FingoCardCompleteTopic {
    background-color: #333;
    /* border: 2px solid #888888; */
}

.FingoCardCompleteTopic .xp-header {
    margin-bottom: 0.8rem;
}

.FingoCardCompleteTopic .xp-name {
    color: #444;
    font-size: 14px;
}

[data-theme='dark'] .FingoCardCompleteTopic .xp-name {
    color: #e2e2e2;
}
