.FingoCardDayStreak {
    padding: 0.75rem;
    background-color: #fff5d4;
}

[data-theme='dark'] .FingoCardDayStreak:not(.highlight) {
    background-color: rgb(255 150 0 / 22%);
}

/* [data-theme='dark'] .FingoCardDayStreak {
    background-color: #484438;
} */

.FingoCardDayStreakInner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.FingoCardDayStreakInner .left {
    width: 25%;
    margin-left: -14px;
}

.FingoCardDayStreakInner .right {
    width: 75%;
}

.FingoCardDayStreak.highlight {
    background: rgb(255, 182, 1);
    background: -moz-linear-gradient(
        283deg,
        rgba(255, 182, 1, 1) 0%,
        rgba(255, 148, 1, 1) 35%
    );
    background: -webkit-linear-gradient(
        283deg,
        rgba(255, 182, 1, 1) 0%,
        rgba(255, 148, 1, 1) 35%
    );
    background: linear-gradient(
        283deg,
        rgba(255, 182, 1, 1) 0%,
        rgba(255, 148, 1, 1) 35%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffb601",endColorstr="#ff9401",GradientType=1);
}

.FingoCardDayStreak h4 {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 0.5rem;
}

.FingoCardDayStreak.highlight h4 {
    color: #ffff;
}

.FingoCardDayStreak p {
    margin-bottom: 0;
    font-size: 0.9rem;
    font-weight: bold;
}

.FingoCardDayStreak.highlight p {
    color: #ffff;
}

.FingoCardDayStreakImg {
    display: flex;
    align-items: center;
    justify-content: center;
}

.FingoCardDayStreakImg img {
    width: 80%;
    height: auto;
}

.FingoCardDayStreakWrapper {
    padding-left: 0;
}

.FingoCardDayStreakContainer {
    padding: 1.1rem;
}

@media (min-width: 576px) {
    .FingoCardDayStreakWrapper {
        padding-left: 280px;
    }
    .FingoCardDayStreakContainer {
        padding: 2.5rem;
    }
}

.FingoCardDayStreakCalendar {
    width: 100%;
    background-color: #ffff;
    border-radius: 0.4rem;
    padding: 0.8rem 0.6rem;
}

[data-theme='dark'] .FingoCardDayStreakCalendar {
    background-color: #161616;
}

.FingoCardDayStreakCalendar ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.FingoCardDayStreakCalendar li {
    display: flex;
    width: 14.2%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.FingoCardDayStreakCalendar li a {
    text-decoration: none;
    user-select: none;
    color: #00a7ff;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.FingoCardDayStreakCalendar li p {
    text-transform: uppercase;
    font-weight: 700;
    /* color: #5f5f5f; */
    margin-bottom: 0.35rem;
    font-size: 0.9rem;
}

.FingoCardDayStreakCalendar li svg {
    width: 16px;
    height: auto;
}
.FingoCardDayStreakCalendar li svg + svg {
    display: none;
}
.FingoCardDayStreak.highlight .FingoCardDayStreakCalendar li p {
    color: #575757;
}
.FingoCardDayStreakCalendar li p.active {
    color: #ff9601 !important;
}

.FingoCardDayStreakCalendar li a {
    background-color: #e5e5e5;
}

[data-theme='dark'] .FingoCardDayStreakCalendar li a {
    background-color: #454545;
}

.FingoCardDayStreak .FingoCardDayStreakCalendar li a.active {
    background-color: #ff9601;
}

.FingoCardDayStreakCalendar li.active svg {
    color: #fff;
    fill: #fff;
}

.FingoCardDayStreakCalendar li.active svg path {
    color: #fff;
    fill: #fff;
}
