.FingoLessonComplete {
    /* border: 2px solid rgb(109 109 109 / 10%); */
    margin-bottom: 1rem;
    border-radius: 2rem;
    max-width: 90%;
    width: 370px;
    margin: 0 auto;
    position: relative;
    background-size: cover;
    background-position: bottom left;
    background-repeat: no-repeat;
    color: #fff;
}

.LessonCompleteOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    /* background: rgb(0 0 0 / 35%); */
    background: rgb(0, 69, 255);
    background: -moz-linear-gradient(
        180deg,
        rgb(16 52 147 / 81%) 0%,
        rgba(37, 100, 255, 0.35) 100%
    );
    background: -webkit-linear-gradient(
        180deg,
        rgb(16 52 147 / 81%) 0%,
        rgba(37, 100, 255, 0.35) 100%
    );
    background: linear-gradient(
        180deg,
        rgb(16 52 147 / 81%) 0%,
        rgba(37, 100, 255, 0.35) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0045ff",endColorstr="#2564ff",GradientType=1);
}

.FingoLessonCompleteContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2rem 2rem 2rem;
    position: relative;
}

.FingoLessonCompleteContent h2 {
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
}

.FingoLessonCompleteContent h6 {
    font-weight: bold;
    font-size: 32px;
    text-transform: uppercase;
}

.FingoLessonCompleteContent .btn {
    margin-bottom: 0.65rem;
}

.StarContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    color: #ffc70f;
    margin-top: -10px;
    padding: 0 10px;
}

.StarContainer img,
.StarContainer svg {
    height: auto;
    width: 100%;
}

.StarContainer div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.StarContainer > div:nth-child(1) img {
    transform: rotate(-20deg);
    width: 94%;
}

.StarContainer > div:nth-child(2) img {
    transform: translateY(-20px);
    width: 110%;
}

.StarContainer > div:nth-child(3) img {
    transform: rotate(20deg);
    width: 94%;
}

.FingoLessonCompleteHeader {
    background-color: transparent;
    position: relative;
    padding: 1rem 0;
    border-bottom: 2px solid rgb(109 109 109 / 10%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}

.FingoLessonCompleteHeader h2 {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 700;
}
