.FingoMobileMenu {
    z-index: 1;
    background-color: #ffffff;
    padding: 1.2rem 0;
}

[data-theme='dark'] .FingoMobileMenu {
    background-color: #3c3c3c;
}

.FingoMobileMenuInner {
    padding: 0 1.4rem;
    min-height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.FingoMobileMenu .navbar-brand {
    width: 100%;
    margin-right: 0;
    padding-bottom: 2rem;
    margin-left: 1rem;
    cursor: pointer;
}

.FingoMobileMenuLogo {
    height: 48px;
    width: auto;
}

.FingoMobileMenuSwitchContainer {
    margin-top: 1rem;
}

@media (min-width: 576px) {
    .FingoMobileMenuSwitchContainer {
        margin-bottom: 0;
    }
}

.FingoMobileMenu ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
}

.FingoMobileMenu li {
    margin-bottom: 0.5rem;
    display: inline-block;
    width: 100%;
}

.FingoMobileMenu li a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    border: 2px solid transparent;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    text-transform: uppercase;
    user-select: none;
    color: #444;
}

[data-theme='dark'] .FingoMobileMenu li a {
    color: #fbfbfb;
}

[data-theme='dark'] .FingoMobileMenu li a {
    border: 2px solid transparent;
}

.FingoMobileMenu li a:hover,
.FingoMobileMenu li a.active {
    background-color: rgba(221, 244, 255);
    border: 2px solid rgba(132, 216, 255);
    color: rgba(28, 176, 246);
}

[data-theme='dark'] .FingoMobileMenu li a:hover,
[data-theme='dark'] .FingoMobileMenu li a.active {
    background-color: #487286;
    border: 2px solid #5c99b6;
    color: #ffffff;
}

.FingoMobileMenu li a .icon {
    margin-right: 1.2rem;
}

.FingoMobileMenu li a .icon img,
.FingoMobileMenu li a .icon svg {
    width: 34px;
    height: auto;
}

.FingoMobileMenu li a .icon {
    margin-right: 1.2rem;
}

.FingoMobileMenu li a span {
    font-size: 16px;
    font-weight: bold;
}
