.FingoModal,
.modal.show {
    z-index: 1061;
}

.modal-backdrop.show {
    z-index: 1060;
}

.FingoModal .modal-dialog {
    margin: 0 auto;
    width: 90%;
}

@media (min-width: 576px) {
    .FingoModal .modal-dialog {
        margin: 0 auto;
        width: 100%;
    }
}

.FingoModal .modal-content {
    border-radius: 12px;
    border: 2px solid rgb(0 0 0 / 2%);
    padding: 0;
    background-color: transparent;
    margin-top: 2rem;
}

@media (min-width: 576px) {
    .FingoModal .modal-content {
        border-radius: 16px !important;
    }
}

[data-theme='dark'] FingoModal .modal-content {
    border: 2px solid rgb(255 255 255 / 28%);
}

.FingoModalClose {
    width: 38px;
    height: 38px;
    border-radius: 38px;
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    position: absolute;
    top: -20px;
    right: -20px;
    background: #ff4141;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 10px;
    color: #fff;
    z-index: 1001;
    margin: 0;
    padding: 0;
}

.FingoModalClose svg {
    width: 20px;
    height: auto;
    margin-top: -2px;
}
