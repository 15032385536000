.HowItsWorkSection {
    padding-bottom: 3rem;
}

.HowItsWorkSection .HowItsWorkItemContainer.left {
    flex-direction: row;
}

.HowItsWorkSection .HowItsWorkItemContainer.right {
    flex-direction: row-reverse;
}

.HowItsWorkItemContainer {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    /* flex: 1; */
}

.HowItsWorkTextContainer {
    width: 45%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

@media (max-width: 576px) {
    .HowItsWorkTextContainer {
        order: 2;
    }
    .HowItsWorkTextContainer {
        order: 3;
    }
}

.HowItsWorkItemContainer .HowItsWorkTextContainer.left {
    padding-right: 100px;
    padding-left: 40px;
}

.HowItsWorkItemContainer .HowItsWorkTextContainer.right {
    padding-left: 100px;
    padding-right: 40px;
}

.HowItsWorkTextContainer h2 {
    font-size: 2rem;
    font-weight: 700;
    color: #4caf50;
    margin-bottom: 2rem;
}

.HowItsWorkTextContainer h6 {
    font-size: 1.25rem;
    line-height: 1.65;
    font-weight: 700;
}

.HowItsWorkItemImage {
    width: 45%;
    padding-left: 100px;
}

.HowItsWorkItemContainer .HowItsWorkItemImage.left {
    padding-left: 100px;
    padding-right: 40px;
}

.HowItsWorkItemContainer .HowItsWorkItemImage.right {
    padding-right: 100px;
    padding-left: 40px;
}

.HowItsWorkItemImage img {
    width: 100%;
    height: auto;
    border: 4px solid #c8c8c8;
    border-radius: 24px;
    background-color: #f7fcf7;
}

.HowItsWorkItemImage.active img {
    border: 4px solid #00cd9c;
}

.HowItsWorkItemIcon {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 576px) {
    .HowItsWorkItemIcon {
        order: -1;
    }
}

.FirstLine {
    height: 45%;
    transform: translateY(-45%);
    width: 4px;
    background-color: #c8c8c8;
    display: flex;
    position: absolute;
    left: 49%;
    transition: all 0.95s cubic-bezier(0.65, 0.02, 0.44, 1.14);
}

.FirstLine.active {
    background-color: #00cd9c;
}

.FirstLineInner {
}

.SecondLine {
    height: 60%;
    transform: translateY(50%);
    width: 4px;
    background-color: #c8c8c8;
    position: absolute;
    left: 49%;
    transition: all 0.95s cubic-bezier(0.65, 0.02, 0.44, 1.14);
}

.SecondLine.active {
    background-color: #00cd9c;
}

.MarkerIcon {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    cursor: pointer;
    display: inline-flex;
    outline: none;
    position: relative;
    touch-action: manipulation;
    transform: translateZ(0);
    --web-ui_button-filter-hover: none;
    height: 60px;
    position: relative;
    width: 76px;
    z-index: 0;
    border-radius: 100px;
}

.MarkerIcon.inactive:after {
    background: #cacaca;
    box-shadow:
        0 10px 0 #9e9e9e,
        0 10px 0 rgb(0 0 0);
}

.MarkerIcon {
    --path-level-color: rgb(229, 229, 229);
}

.MarkerIcon:active {
    transform: translateY(10px);
}

.MarkerIcon:active:after {
    box-shadow: none;
}

.MarkerIcon:active:before {
    display: none;
}

.sub_category_card_content_row {
    margin-top: 2.4rem;
}

.sub_category_card_content_item {
    width: 100%;
    flex-basis: 1;
}

.sub_category_card_content_row .sub_category_card_content_item:last-child {
    margin-bottom: 60px;
}

.MarkerIcon::after {
    content: '';
    background: #00cd9c;
    border-radius: 50%/50%;
    box-shadow:
        0 10px 0 #00a47d,
        0 10px 0 rgb(0 0 0);
    height: 60px;
    top: 0;
    transition: all 0.25 cubic-bezier(0.65, 0.02, 0.44, 1.14);
}

.MarkerIcon::before {
    background: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.2));
    height: 10px;
    top: 28.5px;
    transition: all 0.25 cubic-bezier(0.65, 0.02, 0.44, 1.14);
}

.MarkerIcon::after,
.MarkerIcon::before {
    content: '';
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
    transition: all 0.25 cubic-bezier(0.65, 0.02, 0.44, 1.14);
}

.HowItsWorkIcon- {
    position: absolute;
    left: 22px;
    top: 7px;
}

div[class^='HowItsWorkIcon'] svg {
    color: #ffffff;
    width: 32px;
    height: auto;
    margin-top: 6px;
}

@media (max-width: 576px) {
    .HowItsWorkItemIcon {
        position: absolute;
        height: 100%;
    }
    .HowItsWorkSection .HowItsWorkItemContainer {
        flex-direction: column !important;
        position: relative;
    }
    .HowItsWorkSection .HowItsWorkTextContainer {
        width: 100%;
        padding-left: 90px !important;
        padding-right: 14px !important;
        margin-bottom: 40px;
    }
    .HowItsWorkSection .HowItsWorkItemImage {
        width: 100%;
        padding-left: 90px !important;
        padding-right: 14px !important;
        margin-bottom: 24px;
    }
    .HowItsWorkTextContainer h2 {
        margin-bottom: 1rem;
        font-size: 1.65rem;
    }
    .HowItsWorkTextContainer h6 {
        font-size: 1rem;
    }
    .MarkerIcon::after {
        height: 42px;
        width: 58px;
    }
    .MarkerIcon {
        height: 48px;
        width: 74px;
    }
    div[class^='HowItsWorkIcon'] svg {
        width: 27px;
    }
    .HowItsWorkIcon- {
        left: 15px;
        top: 2px;
    }

    .FirstLine {
        left: 77%;
    }
    .SecondLine {
        left: 77%;
    }
}
