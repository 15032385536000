input[type="file"]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #084cdf;
    /* padding: 10px 20px; */
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
    background: #0d45a5;
}

.welcome-card {
    /*background: linear-gradient(to right, #1a5928, #75ed8f);*/
    border-radius: 15px;
    color: white;
    padding: 20px;
}

.skill-card {
    border-radius: 8px;
    border: 1px solid #dee2e6;
    padding: 20px;
}

.categories {
    margin-top: 10px;
}

.checkmark-icon {
    font-size: 1.2rem;
    color: gray;
    margin-left: 10px;
}

.checkmark-icon.completed {
    color: green;
}
