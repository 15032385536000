.ForgotPasswordForm {
    background-color: #ffffff;
    width: 100%;
    padding: 2.4rem 2rem;
}

@media (min-width: 576px) {
    .ForgotPasswordForm {
        padding: 3rem 2.4rem;
    }
    .ModalForgotPasswordForm .modal-dialog {
        max-width: 680px;
    }
}

.ForgotPasswordForm .valid-feedback,
.ForgotPasswordForm .invalid-feedback {
    display: block !important;
    font-size: 0.9rem;
    font-weight: bold;
}

.ForgotPasswordForm h2 {
    font-weight: 700;
    font-size: 24px;
}

.ForgotPasswordForm p {
    font-size: 0.9rem;
}

.ForgotPasswordFormSuccess {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ForgotPasswordFormSuccess .ForgotIcon {
    width: 48px;
    height: auto;
    margin-bottom: 1.2rem;
    color: #009b4e;
}

.ForgotPasswordForm .form-label {
    font-weight: 600;
    font-size: 0.9rem;
}

[data-theme='dark'] .ForgotPasswordForm {
    background-color: #333;
}
