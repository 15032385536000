@media (min-width: 576px) {
    .ModalKeepLearning .modal-dialog {
        max-width: 384px;
    }
}

.ModalKeepLearningContainer {
    background-color: #ffffff;
    width: 100%;
    padding: 1.75rem 1.8rem;
}

[data-theme='dark'] .ModalKeepLearningContainer {
    background-color: #2b2b2b;
}

@media (min-width: 576px) {
    .ModalKeepLearningContainer {
        padding: 2.2rem 2rem;
    }
}

@media (min-width: 576px) {
    .ModalKeepLearning .ListReward {
    }
}

.KeepLearning {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.KeepLearningImg {
    width: 85px;
    height: auto;
}

.KeepLearning h2 {
    font-weight: 700;
    font-size: 22px;
}

.KeepLearning h4 {
    font-size: 16px;
    color: #444;
}

[data-theme='dark'] .KeepLearning h4 {
    color: #ececec;
}

.ModalRefillCancelBtn {
    color: red;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
}

.ModalRefillCancelBtn:hover {
    color: #7f0000;
}
