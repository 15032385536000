@media (min-width: 576px) {
    .ModalInviteFriends .modal-dialog {
        max-width: 430px;
    }
}

.ModalInviteFriendsContainer {
    background-color: #ffffff;
    width: 100%;
    padding: 1.75rem 1.8rem;
}

[data-theme='dark'] .ModalInviteFriendsContainer {
    background-color: #2b2b2b;
}

@media (min-width: 576px) {
    .ModalInviteFriendsContainer {
        padding: 2rem 2rem;
    }
}

@media (min-width: 576px) {
    .ModalInviteFriends .ListReward {
    }
}

.InviteFriends {
}

.InviteFriendsHeader svg {
    color: #bdbdbd;
    width: 27px;
    height: auto;
}

[data-theme='dark'] .InviteFriendsHeader svg {
    color: #616161;
}

.InviteFriendsHeader svg.active {
    color: #ff4b4b;
}

.InviteFriendsHeader h2 {
    font-weight: 700;
    font-size: 22px;
}

.InviteFriendsHeader h4 {
    font-size: 15px;
    color: #444;
    line-height: 1.5;
}

[data-theme='dark'] .InviteFriendsHeader h4 {
    color: #ececec;
}

.InviteFriendsHeader ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.InviteFriendsHeader img {
    width: 100px;
    height: auto;
}

.InviteFriendsContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.InviteFriendsBtn {
    display: flex;
    align-items: center;
    justify-self: center;
    width: 100%;
    border: none;
    outline: none;
    padding: 4px 8px;
    border-radius: 12px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    background-color: #fff;
    border: 2px solid rgb(109 109 109 / 10%);
    box-shadow: rgb(13 13 13 / 20%) 0px 4px;
    transition: all 0.2s ease 0s;
}

[data-theme='dark'] .InviteFriendsBtn {
    background-color: #1b1b1b;
    border: 2px solid rgb(109 109 109 / 10%);
    box-shadow: rgba(214, 214, 214, 0.2) 0px 4px;
    color: #fbfbfb;
}

.InviteFriendsBtn:hover {
    background-color: rgb(13 13 13 / 5%);
}

.InviteFriendsBtn svg {
    width: 35px;
    height: auto;
    margin-right: 0.5rem;
}
.InviteFriendsBtn .EndContent {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 0.3rem;
}
.InviteFriendsBtn .EndContent svg {
    width: 18px;
    height: auto;
    margin-right: 0.2rem;
}

.InviteFriendsTextBtn {
    font-weight: 700;
    text-transform: uppercase;
}
