.RewardCardItem {
    transition: all 0.2s;
}

.RewardCardItem:hover {
    transform: translateY(4px);
    border: 2px solid #58cc02 !important;
}

.RewardCardItem .RedeemBtn {
    background-color: #58cc02;
    color: #fff;
    border-radius: 2rem;
    font-weight: bold;
    font-size: 0.8rem;
}

.RewardCardItem .card-body {
    padding: 1rem 1.2rem;
}

.RewardCardItem h4 {
    font-size: 17px;
    font-weight: 700;
}

.RewardCardItem p {
    color: #747474;
    font-weight: 700;
    font-size: 0.9rem;
}

.RewardCardItem .TitleContainer {
    height: 44px;
    overflow: hidden;
}
