.FingoCardDailyXP {
    padding: 1rem;
    background-color: #ffffff;
    position: relative;
}

[data-theme='dark'] .FingoCardDailyXP {
    background-color: #333;
}

.FingoCardDailyXP .FingoIconButton {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: rgba(0, 0, 0, 0.1);
    height: 26px;
    width: 26px;
    color: #444;
}

.FingoCardDailyXPHeader {
    margin-bottom: 1.25rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.FingoCardDailyXPHeader a {
    font-weight: 600;
    font-size: 1rem;
}

.FingoCardDailyXP .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.2rem;
}

.FingoCardDailyXP h6 {
    font-size: 17px;
    font-weight: bold;
}

.FingoCardDailyXP .xp-name {
    color: #444;
    font-size: 13px;
}

[data-theme='dark'] .FingoCardDailyXP .xp-name {
    color: #e2e2e2;
}

.FingoCardDailyXPInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
}

.FingoCardDailyXPInner .left {
    width: 17.5%;
}

.FingoCardDailyXPInner .left svg {
    margin-left: -7%;
    width: 98%;
    height: auto;
}

.FingoCardDailyXPInner p {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 15px;
    color: #333;
}

[data-theme='dark'] .FingoCardDailyXPInner p {
    color: #c4c4c4;
}

.FingoCardDailyXPInner .right {
    width: 82.5%;
}

.FingoCardDailyXPContent {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
}

.FingoCardDailyXPContent .icImg {
    width: 12%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    z-index: 1;
}

.FingoCardDailyXPContent .icImg img {
    width: 30px;
    height: auto;
}

.FingoCardDailyXPContent .progress {
    width: 88%;
    position: relative;
    height: 20px;
    border-radius: 0.6rem 0 0 0.6rem;
}

[data-theme='dark'] .FingoCardDailyXPContent .progress {
    background-color: #616569;
}

.FingoCardDailyXPContent .xp-count {
    position: absolute;
    bottom: 9px;
    width: 100%;
    font-size: 13px;
}
