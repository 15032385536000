.FingoUserInfo {
    border-radius: 10px;
    background-color: #f7fcf7;
    /* border: 2px solid rgb(109 109 109 / 10%); */
    /* margin-bottom: 1rem; */
}

.FingoUserInfoInner {
    padding: 0.85rem 0.4rem 1.1rem 1rem;
}

.FingoUserInfo .profile-picture {
    position: relative;
}

.FingoUserInfo .user-name {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0;
}

.FingoUserInfo .FingoCardDailyXPHeaderLevel {
    background-color: rgb(40, 167, 69);
    border-radius: 1rem;
    color: #ffffff;
    display: inline-block;
    padding: 0.25rem 0.5rem;
    font-weight: bold;
    font-size: 0.65rem;
    line-height: 1;
    position: absolute;
    bottom: -12px;
    white-space: nowrap;
    z-index: 1;
}

.FingoUserInfo .greetingText {
    font-size: 15px;
    color: #5c5c5c;
    margin-bottom: 0.15rem;
    font-weight: 500;
}

[data-theme='dark'] .FingoUserInfo .greetingText {
    color: #bdbdbd;
}

.FingoUserInfo .profile-picture {
    display: flex;
    align-items: center;
    justify-content: center;
}

.FingoUserInfo label {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    overflow: hidden;
    margin-bottom: 0;
    display: flex;
}

.UploadPictureContainer:hover .UploadMarker {
    display: flex;
}

.FingoUserInfo .UploadMarker {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
}

.FingoUserInfo label img {
    width: 48px;
    height: auto;
}

[data-theme='dark'] .FingoUserInfo {
    background-color: #2f2f2f;
}
