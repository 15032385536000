.QuizPageRoot {
    position: relative;
}

.QuizPageContainer {
    padding-top: 1.2rem;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 480px;
    position: relative;
    padding-bottom: 100px; /* only for mobile */
}

@media (min-width: 576px) {
    .QuizPageContainer {
        padding-bottom: 0;
    }
}

.option_quiz_container {
    display: block;
    padding: 1.25rem 0px !important;
}

.option_quiz_container .list-group-item {
    background-color: #ffffff;
    border: none !important;
    padding-top: 0 !important;
    padding-bottom: 16px !important;
    font-weight: bold;
    font-size: 1rem;
}

[data-theme='dark'] .option_quiz_container .list-group-item {
    background-color: rgb(51 51 51);
}

.option_quiz_container .list-group-item .option_quiz_item {
    padding: 10px 12px;
    width: 100%;
    border: 2px solid #d9d9d9;
    border-radius: 0.5rem;
    position: relative;
    padding-right: 24px;
}

[data-theme='dark'] .option_quiz_container .list-group-item .option_quiz_item {
    border: 2px solid rgb(100 100 100);
}

.option_quiz_container .list-group-item .option_quiz_item.selected {
    background-color: #c2d3ff;
    color: #000 !important;
}

.option_quiz_container .list-group-item .option_quiz_item.incorrect {
    background-color: #ffd9d9;
    border: 2px solid #e00000;
    color: #000 !important;
}
.option_quiz_container .list-group-item .option_quiz_item.correct {
    background-color: #d4ffe8;
    border: 2px solid #039027;
    color: #000 !important;
}

.option_quiz_container .list-group-item .option_quiz_item input[type='radio'] {
    display: none !important;
}
.option_quiz_container
    .list-group-item
    .option_quiz_item
    input[type='checkbox'] {
    display: none !important;
}

.option_quiz_container .list-group-item .option_quiz_item svg {
    width: 28px;
    height: 28px;
    position: absolute;
    right: 10px;
}
.explanation_btn {
    font-weight: bold;
    color: #4285f4;
}

.RenderBlockQuiz {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.QuizSubmitBtn {
    max-width: 120px;
}
