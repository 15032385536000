.notfound {
    text-align: center;
}

.notfound h2 {
    margin: 40px 0;
    font-size: 205rem;
}

.notfound img {
    width: 600px;
    max-width: 75%;
}

.notfound h4 {
    margin: 40px 0 20px 0;
    font-size: 1.2rem;
}

.main-btn {
    padding: 15px;
    background-color: #6c63ff;
    color: #fff;
    border: none;
    font-weight: 700;
    letter-spacing: 1px;
    border-radius: 6px;
}
