@media (min-width: 576px) {
    .ModalConfirmRefill .modal-dialog {
        max-width: 340px;
    }
}

.ModalConfirmRefillContainer {
    background-color: #ffffff;
    width: 100%;
    padding: 1.75rem 1.8rem;
}

[data-theme='dark'] .ModalConfirmRefillContainer {
    background-color: #2b2b2b;
}

@media (min-width: 576px) {
    .ModalConfirmRefillContainer {
        padding: 2.2rem 2rem;
    }
}

@media (min-width: 576px) {
    .ModalConfirmRefill .ListReward {
    }
}

.RefillHearts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.RefillHeartsIcon {
    width: 100px;
    height: auto;
}

.RefillHearts h2 {
    font-weight: 700;
    font-size: 22px;
}

.RefillHearts h4 {
    font-size: 16px;
    color: #444;
}

.RefillHearts .TextWithIcon {
    vertical-align: middle;
}

.RefillHearts .TextWithIcon svg {
    width: 20px;
    width: auto;
}

.RefillHearts .TextWithIcon svg.HeartSvg {
    width: 20px;
    height: auto;
}

[data-theme='dark'] .RefillHearts h4 {
    color: #ececec;
}

.RefillHeartsImgEnd {
    color: red;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
}

.RefillHeartsImgEnd:hover {
    color: #7f0000;
}
