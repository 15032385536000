.FingoCardTotalXP {
    padding: 1.1rem 1rem;
    position: relative;
    background-color: #ffffff;
}

[data-theme='dark'] .FingoCardTotalXP {
    background-color: #333;
    /* border: 2px solid #888888; */
}

.FingoCardTotalXPHeaderLevelContainer {
    height: 45px;
}

.FingoCardTotalXPHeaderLevel {
    background-color: rgb(40, 167, 69);
    border-radius: 1rem;
    color: #ffffff;
    display: inline-block;
    padding: 0.4rem 1rem;
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 1;
}

.FingoCardTotalXP .xp-header {
    margin-bottom: 0.5rem;
    color: #494949;
    text-align: center;
    justify-content: center;
}

.FingoCardTotalXP .xp-header p {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 17px;
    line-height: 1;
}

/* .FingoCardTotalXP .xp-name {
    color: #444;
    font-size: 13px;
} */

[data-theme='dark'] .FingoCardTotalXP p {
    color: #e2e2e2;
}

.FingoCardTotalXPInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    padding-top: 14px;
}

.FingoCardTotalXPInner .left {
    width: 15%;
    padding-top: 0.2rem;
}

.FingoCardTotalXPInner .left svg {
    width: 75%;
    height: auto;
    color: #ffd900;
}

.FingoCardTotalXPInner .right {
    width: 85%;
    padding-right: 25%;
}

.FingoCardTotalXP.Level-8 .FingoCardTotalXPImg {
    height: 63%;
    width: 63%;
    top: -7px;
    right: 1%;
}

.FingoCardTotalXP.Level-8 .FingoCardTotalXPInner .right {
    padding-right: 0;
}

.FingoCardTotalXPContent {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
}

/* .FingoCardTotalXPContent .icImg {
    width: 12%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    z-index: 1;
} */

.FingoCardTotalXPContent .progress {
    width: 100%;
    position: relative;
    height: 16px;
    border-radius: 16px;
}

.FingoCardTotalXPContent .progress-bar {
    /* background-color: rgb(0 205 156) !important; */
}

.FingoCardTotalXPContent .xp-count {
    position: absolute;
    bottom: 9px;
    width: 100%;
    font-size: 13px;
}

.FingoCardTotalXPImg {
    position: absolute;
    background-position: bottom right;
    background-size: contain;
    background-repeat: no-repeat;
    height: 74%;
    width: 74%;
    top: 14px;
    right: 2%;
}

.FingoCardTotalXP .FingoIconButton {
    position: absolute;
    top: 4px;
    right: 4px;
    height: 24px;
    width: 24px;
    color: #555555;
    background-color: transparent;
    background-color: rgb(226 226 226 / 40%);
}

[data-theme='dark'] .FingoCardTotalXP .FingoIconButton {
    color: #c7c7c7;
    background-color: rgb(51 51 51 / 40%);
}
