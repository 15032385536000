.ResetPasswordPage {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ResetPasswordPage .FingoLogo {
    height: 50px;
    width: auto;
    margin-bottom: 1.4rem;
    margin-top: 0;
}

@media (min-width: 576px) {
    .ResetPasswordPage .FingoLogo {
        margin-top: -100px;
    }
}

.ResetPasswordForm {
    width: 100%;
    padding: 1.4rem 1.5rem;
    background-color: #fff;
    border: 2px solid rgb(109 109 109 / 10%);
}

[data-theme='dark'] .ResetPasswordForm {
    background-color: #2f2f2f;
}

.ResetPasswordFormHeader h2 {
    font-size: 24px;
    font-weight: 700;
}

@media (min-width: 576px) {
    .ResetPasswordForm {
        width: 420px;
        padding: 2.4rem 3rem;
    }
}
