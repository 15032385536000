.FingoHomeLayout {
    background-color: #ffffff;
    min-height: 100vh;
}

[data-theme='dark'] .FingoHomeLayout {
    background-color: #313131;
}

.FingoHomeLayoutWrapper {
    padding-left: 0;
    min-height: 100vh;
}

.FingoHomeLayoutContainer {
    padding: 0 0.6rem;
    position: relative;
}

@media (min-width: 576px) {
    .FingoHomeLayout {
        padding-bottom: 0;
    }
    .FingoHomeLayoutWrapper {
        padding-left: 280px;
    }
    .FingoHomeLayoutContainer {
        padding: 0 1.2rem;
    }
}
