.FingoWidgetHeaderInner {
    height: 100%;
}

.FingoWidgetHeader {
    display: block;
    width: 100%;
    z-index: 3;
    margin-bottom: 0.8rem;
}

@media (min-width: 576px) {
}

.FingoWidgetHeader ul {
    height: 100%;
    padding-left: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.FingoWidgetHeader li {
    display: flex;
    width: 25%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.FingoWidgetHeader li a {
    text-decoration: none;
    user-select: none;
    height: 42px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    position: relative;
}

.FingoWidgetHeader li a.active,
.FingoWidgetHeader li a:hover,
.FingoWidgetHeader li a:focus {
    background-color: #f0f0f0;
}

[data-theme='dark'] .FingoWidgetHeader li a.active,
[data-theme='dark'] .FingoWidgetHeader li a:hover,
[data-theme='dark'] .FingoWidgetHeader li a:focus {
    background-color: #1e1e1e;
}

.FingoWidgetHeader li a span {
    font-weight: 700;
    font-size: 0.9rem;
    margin-left: 0.35rem;
    margin-top: 0.1rem;
    color: #d4d4d4;
}

[data-theme='dark'] .FingoWidgetHeader li a span {
    color: #727272;
}

@media (min-width: 576px) {
    .FingoWidgetHeader li a {
        height: 44px;
        width: 90%;
    }
}

.FingoWidgetHeader li a img {
    height: 26px;
    width: auto;
}

/* #FingoWidgetHeaderRoot .popover {
    max-width: 100%;
    width: 100%;
    left: 0 !important;
    transform: translate3d(0px, 46.5px, 0px) !important;
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0 1rem;
} */

.FingoWidgetHeaderBadge {
    position: absolute;
    top: 2px;
    right: 4px;
    height: 12px;
    width: 12px;
    border-radius: 12px;
    background-color: red;
}
