.FingoBackToTop {
    width: 42px;
    height: 50px;
    outline: none;
    background-color: rgb(0 205 156);
    border: none;
    padding: 5px;
    border-radius: 10px;
    box-shadow: rgb(0, 148, 113) 0px 6px;
    transition: all 0.2s ease 0s;
    font-weight: 100;
    position: sticky;
    bottom: 98px;
    right: 32px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    margin-left: auto;
}

.FingoBackToTop.fixed {
    position: fixed;
}

@media (min-width: 576px) {
    .FingoBackToTop {
        bottom: 38px;
    }
}
